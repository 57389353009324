import { LandingHeader } from 'shared/components/LandingHeader';
import { LandingFooter } from 'shared/components/LandingFooter';
import { CitadelSvg, GicSvg, RepublicSvg, RevelioSvg } from 'shared/assets/partners-img';
import './index.scss';
import medal from '../../shared/assets/images/medal.png';
import medal1 from '../../shared/assets/images/medal_1.png';
import medal2 from '../../shared/assets/images/medal_2.png';
import medalUser from '../../shared/assets/images/users_medal.png';

export const Landing = () => {
	return (
		<>
			<div className="absolute-block landing-gradient">
				<div className="absolute-block multi-color-gradient"></div>
				<div className="absolute-block white-gradient-bottom"></div>
			</div>
			<LandingHeader />
			<section className="section__top">
				<div className="container top__inner">
					<div className="top__content">
						<h1 className="top__title">
							Generate high-quality
							<br />
							leads 10X faster
						</h1>
						<a href="https://app.muraena.ai" className="top__link">
							<button className="sign__up top__button">
								<b>Step in</b> — it's free
							</button>
						</a>
					</div>
					<div className="top__sponsors-list">
						<div className="sponsors__img">
							<CitadelSvg />
						</div>
						<div className="sponsors__img">
							<RevelioSvg />
						</div>
						<div className="sponsors__img">
							<GicSvg />
						</div>
						<div className="sponsors__img">
							<RepublicSvg />
						</div>
					</div>
				</div>
			</section>
			<div className="section__inner section__about">
				<div className="container about__inner">
					<div className="about__border"></div>
					<p className="about__text">
						<p className="about__text1">Lead Sourcing has to be simple.</p>
						<br />
						Muraena leverages AI to cut through the chaos, delivering precise and targeted Sales Leads
						without the complexity of traditional systems.
					</p>
					<div className="about__border"></div>
				</div>
			</div>
			<section className="section__inner section__features" id="product">
				<div className="container features__inner">
					<h2 className="section__title">Simplified - Cost-effective - Precise</h2>
					<div className="features__block-inner">
						<div className="features__block">
							<div className="features__small-item features__back-purple">
								<h3 className="features__item-title">AI-Driven Targeting</h3>
								<p className="features__item-descr">
									Skip the hassle of filters. Define your Ideal Customer Profile and let Muraena AI
									find the highest-priority prospects just for you.
								</p>
							</div>
							<div className="features__big-item features__back-pastel">
								<h3 className="features__item-title">140M+ Professionals Covered</h3>
								<p className="features__item-descr">
									Don’t miss a single prospect. Muraena’s database contains 140M+ B2B contacts, 30M
									companies, 180M emails, and 20M phone numbers.
								</p>
							</div>
						</div>
						<div className="features__block">
							<div className="features__big-item features__back-pastel">
								<h3 className="features__item-title">Funding Insights</h3>
								<p className="features__item-descr">
									Direct your efforts towards financially primed companies. Utilize the latest data on
									funding stages, amounts, and recent investment rounds to identify prospects with the
									means and motivation to engage.
								</p>
							</div>
							<div className="features__small-item features__back-blue">
								<h3 className="features__item-title">Buyer Intent Signals</h3>
								<p className="features__item-descr">
									Target companies showing readiness to buy, with insights spanning 7,500 topics and
									250 categories. Make informed decisions by understanding who is actively seeking
									solutions like yours.
								</p>
							</div>
						</div>
						<div className="features__block">
							<div className="features__small-item features__back-green">
								<h3 className="features__item-title">Technographic Profiles</h3>
								<p className="features__item-descr">
									Understand potential clients better by analyzing their technology stack for more
									personalized outreach.
								</p>
							</div>
							<div className="features__big-item features__back-pastel">
								<h3 className="features__item-title">Indexed Business Domains</h3>
								<p className="features__item-descr">
									Access the most comprehensive directory of business domains. Every global business
									website is indexed, unlocking the most niche market segments for you.
								</p>
							</div>
						</div>
						<div className="features__block">
							<div className="features__big-item features__back-pastel">
								<h3 className="features__item-title">Verified Contact Information</h3>
								<p className="features__item-descr">
									Rely on our verified email database for your outreach, ensuring high deliverability
									and engagement rates without extra costs for verification.
								</p>
							</div>
							<div className="features__small-item features__back-orange">
								<h3 className="features__item-title">Integrations</h3>
								<p className="features__item-descr">
									Easily integrate Muraena with your existing sales stack, including Pipedrive,
									Hubspot and lemlist for a streamlined prospecting process.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section__inner section__feedback">
				<div className="container feedback__inner">
					<h2 className="section__title">Trusted by sales experts all over the world</h2>
					<div className="feedback__block">
						<div className="feedback__item">
							<div className="feedback__item-content">
								<img
									className="feedback__item-logo"
									src="https://i.postimg.cc/wMQQTbTM/1689839561606.jpg"
									alt=""
								/>
								<div className="feedback__item-descr">
									<p className="feedback__item-name">Bohdan Ivanov</p>
									<p className="feedback__item-position">
										Retention at Invity.io
									</p>
								</div>
							</div>
							<div className="feedback__item-text">
								It’s like ZoomInfo, but for startups. Easy to validate your sales hypotheses, while
								keeping the lowest price per lead on the market. And even if you want to go “advanced” -
								they have unique features like Buyer Intent signals and Keyword search to generate
								relevant leads only.
							</div>
						</div>
						<div className="feedback__item">
							<div className="feedback__item-content">
								<img
									className="feedback__item-logo"
									src="https://i.postimg.cc/xjZ06T0s/0-Kh-Eh-XAp-KMYwf8-DTA.jpg"
									alt=""
								/>
								<div className="feedback__item-descr">
									<p className="feedback__item-name">Maria Grineva</p>
									<p className="feedback__item-position">
										Founder of
										<a href="https://prog.ai" className="feedback__position-link">
											&nbsp;Prog.AI
										</a>
									</p>
								</div>
							</div>
							<div className="feedback__item-text">
								We decided to give Muraena a try compared to other B2B prospecting solutions in the
								market, and it has become our choice due to its rich functionality and
								cost-effectiveness. Using their AI-based search, we were able to discover new relevant
								market segments.
							</div>
						</div>
						<div className="feedback__item">
							<div className="feedback__item-content">
								<img
									className="feedback__item-logo"
									src="https://i.postimg.cc/Dz5gTN0z/1667418979994.jpg"
									alt=""
								/>
								<div className="feedback__item-descr">
									<p className="feedback__item-name">Alex Southworth</p>
									<p className="feedback__item-position">
										Founder of
										<a href="https://etymon.ai/" className="feedback__position-link">
											&nbsp;EtymonAI
										</a>
									</p>
								</div>
							</div>
							<div className="feedback__item-text">
								This is easily the #1 tool for startups that are building their outreach process from
								scratch. Imagine using RocketReach or ZoomInfo, but paying less than $500 for it. The
								AI-processed ICP is a great time-saver, simplifying the search for the right prospects.
							</div>
						</div>
						<div className="feedback__item">
							<div className="feedback__item-content">
								<img
									className="feedback__item-logo"
									src="https://i.postimg.cc/nc94CzRB/1569875064975.jpg"
									alt=""
								/>
								<div className="feedback__item-descr">
									<p className="feedback__item-name">Mikita Mikado</p>
									<p className="feedback__item-position">
										Co-founder of
										<a href="https://pandadoc.com" className="feedback__position-link">
											&nbsp;Pandadoc
										</a>
									</p>
								</div>
							</div>
							<div className="feedback__item-text">
								As an advisor to Muraena, I'm impressed by team's effectiveness in data-driven lead
								generation. It's a straightforward tool that excels at connecting teams to the right
								leads quickly and accurately.
							</div>
						</div>
						<div className="feedback__item-banners">
							<a
								className="medal"
								href="https://www.g2.com/products/muraena/reviews"
								target="_blank"
								rel="noopener noreferrer">
								<img className="medal" src={medal} alt="" />
							</a>
							<a
								href="https://www.g2.com/products/muraena/reviews"
								target="_blank"
								rel="noopener noreferrer">
								<img className="medal" src={medal1} alt="" />
							</a>
							<a
								href="https://www.g2.com/products/muraena/reviews"
								target="_blank"
								rel="noopener noreferrer">
								<img className="medal" src={medal2} alt="" />
							</a>
							<a
								href="https://www.g2.com/products/muraena/reviews"
								target="_blank"
								rel="noopener noreferrer">
								<img className="medal" src={medalUser} alt="" />
							</a>
						</div>
					</div>
				</div>
			</section>
			<LandingFooter />
		</>
	);
};
