import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'shared/components/ui';
import { BillingPlan } from 'shared/models';
import { useLocalStorage } from 'shared/hooks';
import { MURAENA_EMAIL } from 'shared/utils';
import {
	useCreateCheckoutSessionMutation,
	useCreateModifyCheckoutSessionMutation,
	useGetCurrentUserQuery
} from 'services';

interface Props {
	className?: string;
	billingPlan: string | null;
	priceId?: string;
}

export const CardButton = ({ className, billingPlan, priceId }: Props) => {
	const [url, setUrl] = useState('');
	// const [isNewUser, setIsNewUser] = useLocalStorage('isNewUser', true);

	const navigate = useNavigate();

	const { data } = useGetCurrentUserQuery();
	const [createSession] = useCreateCheckoutSessionMutation();
	const [createModifySession] = useCreateModifyCheckoutSessionMutation();

	useEffect(() => {
		if (url) {
			window.open(url, '_self', 'noopener,nofollow,noreferrer');
		}
	}, [url]);

	const handleFree = () => {
		priceId &&
			createSession({
				plan_id: priceId
			})
				.unwrap()
				.then(link => {
					if (link.switched_to_free) {
						window.location.reload();
					}
				});
	};

	const handleSubscription = () => {
		priceId &&
			createSession({
				plan_id: priceId
			})
				.unwrap()
				.then(link => {
					setUrl(link.redirect_url);
				});
	};

	const handleChangeSubscription = () => {
		priceId &&
			createModifySession()
				.unwrap()
				.then(link => {
					setUrl(link.customer_portal_url);
				});
	};

	const handleClick = () => {
		if (!data) return;
		if (data.billing_plan === BillingPlan.BASIC || data.billing_plan === BillingPlan.BUSINESS) {
			handleChangeSubscription();
			return;
		}
		if ((data.billing_plan === BillingPlan.EXPIRED || data.billing_plan === BillingPlan.TRIAL) && billingPlan === BillingPlan.FREE) {
			handleFree();
			return;
		}
		handleSubscription();

		// console.log(billingPlan)
		// if (billingPlan === BillingPlan.FREE) {
		// 	handleFree();
		// } else {
		// 	if ((data && data.billing_plan !== BillingPlan.FREE) && (data && data.billing_plan !== BillingPlan.EXPIRED)) {
		// 		handleChangeSubscription();
		// 	} else {
		// 		handleSubscription();
		// 	}
		// }
		// // setIsNewUser(false);
	};

	const text =
		data?.billing_plan === BillingPlan.EXPIRED || data?.billing_plan === BillingPlan.TRIAL
			? 'Start plan'
			: billingPlan === data?.billing_plan
			? 'Current plan'
			: 'Change plan';

	// if (
	// 	billingPlan !== data?.billing_plan &&
	// 	!isNewUser &&
	// 	data?.billing_plan !== BillingPlan.FREE &&
	// 	data?.billing_plan !== BillingPlan.EXPIRED
	// )
	// 	return (
	// 		<Button
	// 			className={className}
	// 			component="a"
	// 			href={`mailto:${MURAENA_EMAIL}`}
	// 			type={billingPlan === BillingPlan.BUSINESS ? 'primary' : 'default'}>
	// 			Change plan
	// 		</Button>
	// 	);

	return (
		<Button
			className={className}
			block
			onClick={handleClick}
			// disabled={
			// 	(billingPlan === data?.billing_plan && billingPlan !== BillingPlan.FREE) ||
			// 	(billingPlan === BillingPlan.FREE && !isNewUser)
			// }
			disabled={billingPlan === data?.billing_plan}
			type={billingPlan === BillingPlan.BUSINESS ? 'primary' : 'default'}>
			{text}
		</Button>
	);
};
