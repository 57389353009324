import { FC } from 'react';
import cn from 'classnames';

import { OpenedContactShort, ProgAIProfile } from 'shared/generated-models';
import { Icon, Text, TextVariant } from 'shared/components/ui';

interface Props {
	profile: ProgAIProfile | OpenedContactShort;
	className?: string;
	variant?: TextVariant;
}

export const ProfileCompany: FC<Props> = ({ profile, className, variant }: Props) => {
	if (!profile.company) return null;

	const handleClick = (event: any) => {
		event.stopPropagation();
		if (profile.companyLinkedInUrl) {
			const absoluteUrl = profile.companyLinkedInUrl.startsWith('http') ? profile.companyLinkedInUrl : `http://${profile.companyLinkedInUrl}`;
			window.open(absoluteUrl, '_blank');
		}
	};

	return (
		<div onClick={handleClick} style={{ cursor: 'pointer', zIndex: 5 }}>
			<Text variant={variant} className={className} color={profile.companyLinkedInUrl ? 'light_blue' : 'black'}>
				{`${profile.company}`}
			</Text>
		</div>
	);
};
