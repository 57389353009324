import React, { FC } from 'react';
import cn from 'classnames';

import { getProfileInitials } from 'shared/utils';
import { CandidateProfileAvatar, ProfileName } from 'shared/components';
import { Text } from 'shared/components/ui';
import {
	CandidateSkills,
	CandidateAbout,
	CandidateSection,
	CandidateProfileSkeleton,
	CandidateContacts,
	CandidateInfo
} from 'features/candidate/components';
import { ProgAIResults, ProgAICandidate } from 'shared/generated-models';
import { ProfileVariant } from 'features/candidate';

import styles from './index.module.scss';

interface Props {
	candidate?: ProgAIResults;
	data?: ProgAICandidate['profile'];
	shared?: boolean;
	className?: string;
	variant: ProfileVariant;
}

export const CandidateProfile: FC<Props> = ({ candidate, data, shared, variant, className }: Props) => {
	if (!data) return <CandidateProfileSkeleton />;

	return (
		<section className={cn(styles.profile, className)}>
			<div className={styles.wrapper}>
				<section className={styles.info}>
					{/* <CandidateProfileAvatar src={data.profile_pic_url} size={88} className={styles.infoAvatar}>
						{getProfileInitials(data)}
					</CandidateProfileAvatar> */}
					<div>
						<ProfileName variant="inter/20/bold" profile={data} className={styles.infoName} />
						<Text color="dark_green" variant="inter/14/medium" paragraph className={styles.infoTitle}>
							{data.title}
						</Text>
					</div>
					{!shared && <CandidateContacts data={data} variant={variant} profileId={candidate?.profile.profile_id} />}
				</section>
				<div className={styles.sections}>
					<CandidateInfo data={data} metadata={candidate?.match_metadata} />
					<CandidateAbout data={data} />
					{data.oss_contributions && (
						<CandidateSection
							title={`Github Repositories (${data.oss_contributions.length})`}
							icon="github-logo-circle"
							array={data.oss_contributions}
							sectionName={'oss_contribution'}
						/>
					)}
					{data.experience && (
						<CandidateSection title="Companies" array={data.experience} sectionName={'companies'} />
					)}
					{data.work_experiences && (
						<CandidateSection title="Experience" array={data.work_experiences} sectionName={'experience'} />
					)}
					{data.education && (
						<CandidateSection title="Education" array={data.education} sectionName={'education'} />
					)}
					{data.certifications && (
						<CandidateSection
							title="Licences &amp;&nbsp;certifications"
							array={data.certifications}
							sectionName={'certification'}
						/>
					)}
					{data.awards && (
						<CandidateSection title="Honors &amp;&nbsp;awards" array={data.awards} sectionName={'award'} />
					)}
					{data.publications && (
						<CandidateSection title="Publications" array={data.publications} sectionName={'publication'} />
					)}
					{data.patents && <CandidateSection title="Patents" array={data.patents} sectionName={'patent'} />}
					{data.languages && (
						<CandidateSection title="Languages" array={data.languages} sectionName={'language'} />
					)}
				</div>
			</div>
			{/* <CandidateSkills data={data} /> */}
		</section>
	);
};
