import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import cn from 'classnames';

import Check from 'shared/assets/svg-icons/check-gradient.svg';
import { CenterModal, CreditsSummary, ModalFooter } from 'shared/components';
import { SubscriptionResultCard } from 'features/subscription';
import { Button, Text } from 'shared/components/ui';
import PaymentSuccessIcon from 'shared/assets/svg-icons/payment-success.svg';
import { useGetCurrentUserQuery, useGetUserSubscriptionQuery } from 'services';
import { useLocalStorage } from 'shared/hooks';
import { currencyFormatter } from 'shared/utils';
import { IntervalEnum } from 'shared/generated-models';

import styles from './index.module.scss';

const FEATURES = {
	'AppSumo Tier 1': [
		'2,000 credits/month',
		'Access database of 140M B2B profiles',
		'AI-based Leads Search',
		'Advanced Search Features: Funding, Buyer intent, Technographics',
		'Indexed business domains',
		'Export to CSV, Pipedrive, lemlist, and Hubspot'
	],
	'AppSumo Tier 2': [
		'5,000 credits/month',
		'Access database of 140M B2B profiles',
		'AI-based Leads Search',
		'Advanced Search Features: Funding, Buyer intent, Technographics',
		'Indexed business domains',
		'Export to CSV, Pipedrive, lemlist, and Hubspot'
	],
	'AppSumo Tier 3': [
		'15,000 credits/month',
		'API Access',
		'Access database of 140M B2B profiles',
		'AI-based Leads Search',
		'Advanced Search Features: Funding, Buyer intent, Technographics',
		'Indexed business domains',
		'Export to CSV, Pipedrive, lemlist, and Hubspot'
	],
	'AppSumo Tier 4': [
		'30,000 credits/month',
		'API Access',
		'Access database of 140M B2B profiles',
		'AI-based Leads Search',
		'Advanced Search Features: Funding, Buyer intent, Technographics',
		'Indexed business domains',
		'Export to CSV, Pipedrive, lemlist, and Hubspot'
	],
	'AppSumo Tier 5': [
		'60,000 credits/month',
		'API Access',
		'Access database of 140M B2B profiles',
		'AI-based Leads Search',
		'Advanced Search Features: Funding, Buyer intent, Technographics',
		'Indexed business domains',
		'Export to CSV, Pipedrive, lemlist, and Hubspot'
	]
};

type PlanType = 'AppSumo Tier 1' | 'AppSumo Tier 2' | 'AppSumo Tier 3' | 'AppSumo Tier 4' | 'AppSumo Tier 5';

interface Props {
	plan: string;
}

export const AppSumoModal = ({ plan }: Props) => {
	const [hasOpenedAppSumoModal, setHasOpenedAppSumoModal] = useLocalStorage('hasOpenedAppSumoModal', false);

	const handleClick = () => {
		setHasOpenedAppSumoModal(true);
	};

	if (hasOpenedAppSumoModal) return null;

	return (
		<CenterModal>
			<div className={styles.success}>
				<img src={PaymentSuccessIcon} className={styles.successIcon} alt="payment success icon" />
				<Text component="h1" variant="grotesk/24/bold" lineHeight={106}>
					Welcome Sumo-ing!
				</Text>
				<Text color="grey" component="p" className={styles.description}>
					Your account was sucessfully created 🎉
				</Text>
			</div>

			<div className={styles.cardPerks}>
				<ul>
					<li>
						<Text variant="grotesk/16/regular" component="p">
							Current plan: <span>{plan}</span>
						</Text>
					</li>
					<li>
						<Text variant="grotesk/16/regular" component="p">
							Your list of features:
						</Text>
					</li>
					{FEATURES[plan as PlanType].map(feature => (
						<li>
							<img src={Check} alt="check icon" />
							<Text variant="grotesk/16/regular" component="p">
								{feature}
							</Text>
						</li>
					))}
				</ul>
			</div>
			<div className={styles.wrapper}>
				<Button type="primary" onClick={handleClick}>
					Let's find your leads
				</Button>
			</div>
		</CenterModal>
	);
};
