import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import { antTheme } from 'configs';
import { store } from 'store/store';
import { Analytics } from 'features/analytics';

import { Router } from './routes/Router';
import './style.scss';

Analytics.init();

export const App = () => {
	return (
		<Provider store={store}>
			<ConfigProvider theme={antTheme}>
				<Router />
			</ConfigProvider>
		</Provider>
	);
};
