import { FC, ReactNode, useEffect } from 'react';
import { ConfigProvider, Form, Space, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import {
	Button,
	Checkbox,
	FormikInput,
	FormikInputNumber,
	FormikCheckboxGroup,
	Icon,
	FormikSelectInput,
	FormikSelectInputWithNegative,
	FormikRadioGroup,
	Radio,
	Text,
	TextButton
} from 'shared/components/ui';
import { SearchSelect, SearchSelectWithNegative } from 'features/search/components';
import {
	useGetCompaniesQuery,
	useGetCountriesQuery,
	useGetIndustriesQuery,
	useGetJobTitlesQuery,
	useGetLocationsQuery,
	useGetRegionsQuery,
	useGetSubregionsQuery,
	useGetTechnographicsQuery,
	useGetBuyersIntentQuery
} from 'services';
import { LeadsPerCompany, FundingDate, Headcount } from 'shared/generated-models';
import { SearchFormParams } from 'features/search/models';
import { AdaptiveDevices, formIsEmpty, getAdaptiveDevice } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	heightClassName?: string;
	header: ReactNode;
	className?: string;
	variant?: 'modal' | 'main';
}

export const SearchForm: FC<Props> = ({ heightClassName, header, className, variant }: Props) => {
	const device = getAdaptiveDevice();

	const isSmallDevice = device === AdaptiveDevices.MOBILE || device === AdaptiveDevices.TABLET;
	const tooltipPlacement = isSmallDevice ? 'top' : 'right';

	const { values, setValues, validateForm, handleSubmit } = useFormikContext<SearchFormParams>();

	const buttonsDisabled = formIsEmpty({ ...values, size: undefined, page: undefined });

	const handleClearFilters = () => {
		setValues({});
		validateForm({});
	};

	const isLookup = (values: any) => {
		// if ('first_name' in values && values['first_name']) return true;
		// if ('last_name' in values && values['last_name']) return true;
		// if ('companies' in values && values['companies'].length > 0) return true;
		return false;
	};

	const disabledFields = isLookup(values);

	useEffect(() => {
		if (disabledFields) {
			const newValues: any = {};
			// if ('first_name' in values && values['first_name']) newValues['first_name'] = values['first_name'];
			// if ('last_name' in values && values['last_name']) newValues['last_name'] = values['last_name'];
			if ('linkedin_url' in values && values['linkedin_url']) newValues['linkedin_url'] = values['linkedin_url'];
			if ('companies' in values && values['companies'] && values['companies'].length > 0)
				newValues['companies'] = values['companies'];
			setValues(newValues);
		}
	}, [disabledFields]);

	const removeFundingDateField = () => {
		const newValues = { ...values };
		delete newValues['last_funding_date'];
		setValues(newValues);
	};


	// negative_keywords
	// negative_title_keywords

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<Form layout="vertical" className={cn(styles.form, className)}>
				{header}
				<div className={heightClassName}>
					<div className={styles.section}>
						<div className={styles.row}>
							<SearchSelectWithNegative
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Functional Area</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Select functional area of the prospects within their company.
														We've a curated list of 13 areas/departments in total.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="job_titles"
								useQuery={useGetJobTitlesQuery}
								placeholder="Enter or select functional area"
								disabled={disabledFields}
							/>
							<div className={styles.formSection}>
								<div style={{ marginBottom: '8px' }} className={styles.label}>
									<Text variant="inter/15/medium">Job Title keywords</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													Search target prospects by specific Job Titles, i.e "Big Data",
													"Compliance Officer", "Founder" etc.
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
								<FormikSelectInputWithNegative name="title_keywords" disabled={disabledFields} />
							</div>
						</div>
						<div className={styles.row}>
							<SearchSelectWithNegative
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Industries</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Select industry of the prospect's company. We serve standard
														list of LinkedIn industries + some of the most popular modern
														tech industries, such as SaaS, Sales Automation, etc.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="industries"
								useQuery={useGetIndustriesQuery}
								placeholder="Enter or select industries"
								disabled={disabledFields}
							/>
							<div className={styles.formSection}>
								<div style={{ marginBottom: '8px' }} className={styles.label}>
									<Text variant="inter/15/medium">Company keywords</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													You can describe narrow/specific market segments that are not
													possible to find by Industry search. Muraena will search for the
													keywords occurence on companies' websites and LinkedIn pages. For
													example, "marketing agency"
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
								<FormikSelectInputWithNegative name="keywords" disabled={disabledFields} />
							</div>
						</div>
						<div className={styles.formSection}>
							<div style={{ marginBottom: '8px' }} className={styles.label}>
								<Text variant="inter/15/medium">Find similar companies (look-alike search)</Text>
								<Tooltip
									arrow={false}
									title={
										<div className={styles.tooltip}>
											<Text color="dark_green">
												Submit one or many companies web domains, and Muraena will generate a
												list of look-alike companies, and will search for employees from those
												companies (working in the same industry, target market, etc.)
											</Text>
										</div>
									}
									placement={tooltipPlacement}
									overlayClassName={styles.labelTooltip}>
									<Icon icon="info" className={styles.labelIcon} />
								</Tooltip>
							</div>
							<FormikInput
								name="lookalike"
								size="large"
								placeholder="Enter companies domains, separated by comma"
							/>
						</div>
						<SearchSelectWithNegative
							label={
								<div className={styles.label}>
									<Text variant="inter/15/medium">Buyers Intent</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													Indicates a company's "likelihood to buy" based on their behavioral
													signals. F.e if you selling email marketing services, put "email
													marketing" as a topic
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
							}
							name="bombora_surge_list_topic"
							useQuery={useGetBuyersIntentQuery}
							placeholder="Enter or select Buyers Intent"
							disabled={disabledFields}
						/>
						<SearchSelectWithNegative
							label={
								<div className={styles.label}>
									<Text variant="inter/15/medium">Technographics</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													Company’s technographic data - software platforms and integrations
													they are using.
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
							}
							name="technography"
							useQuery={useGetTechnographicsQuery}
							placeholder="Enter or select technologies"
							disabled={disabledFields}
						/>
						<div className={styles.row}>
							<SearchSelect
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">City or State</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														City or State of the prospect. Please do not specify broader
														location-related filters (Country or Region), when City or State
														is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="locations"
								useQuery={useGetLocationsQuery}
								placeholder="Enter city or state"
								disabled={disabledFields}
							/>
							<SearchSelect
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Country</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Country of the prospect. Please do not specify any other
														location-related filters, when Country is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="countries"
								useQuery={useGetCountriesQuery}
								placeholder="Enter Country"
								disabled={disabledFields}
							/>
						</div>
						<div className={styles.row}>
							<SearchSelect
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Subregion</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Subregion of the prospect. Please do not specify any other
														location-related filters, when Subregion is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="subregions"
								useQuery={useGetSubregionsQuery}
								placeholder="Enter Subregion"
								disabled={disabledFields}
							/>
							<SearchSelect
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Region</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Region of the prospect. Please do not specify any other
														location-related filters, when Region is specified.
													</Text>
												</div>
											}
											placement="right"
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="regions"
								useQuery={useGetRegionsQuery}
								placeholder="Enter Region"
								disabled={disabledFields}
							/>
						</div>

						<div className={styles.formSection}>
							<FormikCheckboxGroup
								name="headcount"
								disabled={disabledFields}
								label={
									<div className={cn(styles.label, styles.rockstarLabel)}>
										<Text variant="inter/15/medium">Headcount</Text>
									</div>
								}>
								<Checkbox value={Headcount._1_10}>1-10</Checkbox>
								<Checkbox value={Headcount._11_50}>11-50</Checkbox>
								<Checkbox value={Headcount._51_200}>51-200</Checkbox>
								<Checkbox value={Headcount._201_500}>201-500</Checkbox>
								<Checkbox value={Headcount._501_1000}>501-1000</Checkbox>
								<Checkbox value={Headcount._1001_5000}>1001-5000</Checkbox>
								<Checkbox value={Headcount._5001_10000}>5001-10000</Checkbox>
								<Checkbox value={Headcount._10000_}>10000+</Checkbox>
							</FormikCheckboxGroup>
						</div>
						<div className={styles.formSection}>
							<div style={{ marginBottom: '8px' }}>
								<Text variant="inter/15/medium">Total Funding Raised</Text>
							</div>
							<div className={cn(styles.row, styles.rowFunding)}>
								<FormikInputNumber
									prefix="$"
									name="total_funding_start"
									size="large"
									placeholder="From"
								/>
								<FormikInputNumber prefix="$" name="total_funding_end" size="large" placeholder="To" />
							</div>
						</div>
						<div className={styles.formSection}>
							<div className={styles.funding}>
								<FormikRadioGroup
									name="last_funding_date"
									disabled={disabledFields}
									label={
										<div className={styles.label}>
											<Text variant="inter/15/medium">Last Funding Date</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															If company raised any funding, here you can select a date of
															their last funding round
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}>
									<Space direction="vertical">
										<Radio key={FundingDate.past_30_days} value={FundingDate.past_30_days}>
											Past 30 days
										</Radio>
										<Radio key={FundingDate.past_90_days} value={FundingDate.past_90_days}>
											Past 90 days
										</Radio>
										<Radio key={FundingDate.past_year} value={FundingDate.past_year}>
											Past Year
										</Radio>
										<Radio key={FundingDate.past_2_years} value={FundingDate.past_2_years}>
											Past 2 Years
										</Radio>
									</Space>
								</FormikRadioGroup>
								{'last_funding_date' in values && (
									<TextButton onClick={removeFundingDateField} className={styles.clear}>
										Clear
									</TextButton>
								)}
							</div>
						</div>
						{/* <div className={styles.formSection}>
							<div style={{ marginBottom: '8px' }}>
								<Text variant="inter/15/medium">First Name</Text>
							</div>
							<FormikInput name="first_name" size="large" placeholder="Enter first name" />
						</div>
						<div className={styles.formSection}>
							<div style={{ marginBottom: '8px' }}>
								<Text variant="inter/15/medium">Last Name</Text>
							</div>
							<FormikInput name="last_name" size="large" placeholder="Enter last name" />
						</div> */}
						<div className={styles.formSection}>
							<div style={{ marginBottom: '8px' }}>
								<Text variant="inter/15/medium">Person LinkedIn URL</Text>
							</div>
							<FormikInput name="linkedin_url" size="large" placeholder="Enter linkedin url" />
						</div>
						<SearchSelectWithNegative
							label="Companies"
							name="companies"
							useQuery={useGetCompaniesQuery}
							placeholder="Enter or select Companies"
						/>

						{/* <div className={styles.formSection}>
							<FormikCheckboxGroup
								name="leads_per_company"
								label={
									<div className={cn(styles.label, styles.rockstarLabel)}>
										<Text variant="inter/15/medium">Leads per company</Text>
									</div>
								}>
								<Checkbox value={LeadsPerCompany._5_}>5+</Checkbox>
								<Checkbox value={LeadsPerCompany._3_5}>3-5</Checkbox>
								<Checkbox value={LeadsPerCompany._1_3}>1-3</Checkbox>
							</FormikCheckboxGroup>
						</div> */}
					</div>
				</div>
				{variant === 'modal' && (
					<div className={styles.formButtons}>
						<Button className={styles.formButton} disabled={buttonsDisabled} onClick={handleClearFilters}>
							Clear filters
						</Button>
						<Button
							className={styles.formButton}
							type="primary"
							disabled={buttonsDisabled}
							onClick={handleSubmit}>
							Get my leads
						</Button>
					</div>
				)}
			</Form>
		</ConfigProvider>
	);
};
