import { FC, KeyboardEvent, ReactNode, useState } from 'react';
import { AutoComplete, Form, Select } from 'antd';
import { Field, FieldProps, useField } from 'formik';
import cn from 'classnames';

import { Tag, Text, TextButton, Tooltip } from 'shared/components/ui';

import styles from './index.module.scss';

interface SelectInputProps {
	name: string;
	disabled?: boolean;
}

export const FormikSelectInputWithNegative = ({ name, disabled }: SelectInputProps) => {
	const [field, , helper] = useField(name);
	const value: string[] = (field.value as []) || [];

	const [negativeField, , negativeHelper] = useField(`negative_${name}`);
	const negativeValue: string[] = (negativeField.value as []) || [];

	const handleSelect = (data: string) => {
		if (value?.includes(data)) {
			if (!negativeValue) {
				negativeHelper.setValue([data]);
			} else {
				negativeHelper.setValue([...negativeValue!, data]);
			}
			handleDelete(data);
			return;
		}
		if (negativeValue?.includes(data)) {
			if (!value) {
				helper.setValue([data]);
			} else {
				helper.setValue([...value!, data]);
			}
			handleDeleteNegative(data);
			return;
		}
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
	};

	const handleSwitch = (data: string) => {
		if (!negativeValue) {
			negativeHelper.setValue([data]);
		} else {
			negativeHelper.setValue([...negativeValue!, data]);
		}
		handleDelete(data);
	};

	const handleSwitchNegative = (data: string) => {
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
		handleDeleteNegative(data);
	};

	const handleDelete = (deleted: string) => {
		helper.setValue(value?.filter((item: string) => item !== deleted) || []);
	};

	const handleDeleteNegative = (deleted: string) => {
		negativeHelper.setValue(negativeValue?.filter((item: string) => item !== deleted) || []);
	};

	const handleChange = (items: string[]) => {
		const previousValues = [...value, ...negativeValue];
		const addedValues = items.filter(v => !previousValues.includes(v));
		// @ts-ignore
		const removedValues = previousValues.filter(v => !items.includes(v));

		addedValues.forEach(val => {
			handleSelect(val);
		});
		// @ts-ignore
		removedValues.forEach(val => {
			handleSelect(val);
		});
	};

	const onClose = (item: ReactNode) => {
		// @ts-ignore
		const isNegativeValue = negativeValue.includes(item);
		if (handleDelete && handleDeleteNegative) {
			// @ts-ignore
			return isNegativeValue ? () => handleDeleteNegative(item) : () => handleDelete(item);
		}
		return () => {};
	};

	return (
		<Field name={name}>
			{({ field, form }: { field: any; form: any }) => (
				<Select
					{...field}
					mode="tags"
					style={{ width: '100%' }}
					placeholder="Enter keywords"
					onChange={handleChange}
					tokenSeparators={[',']}
					tagRender={props => (
						<Tag
							label={props.label}
							onClick={onClose(props.label)}
							// @ts-ignore
							negative={negativeValue.includes(props.label)}
							handleSwitch={
								// @ts-ignore
								negativeValue.includes(props.label)
									? // @ts-ignore
									  () => handleSwitchNegative(props.label)
									: // @ts-ignore
									  () => handleSwitch(props.label)
							}
						/>
					)}
					disabled={disabled}
					value={[...value, ...negativeValue]}
				/>
			)}
		</Field>
	);
};

interface Props {
	onChange?: (value: Array<any>) => void;
	label?: ReactNode;
	id?: string;
	placeholder?: string;
	onClick?: () => void;
	name: string;
	className?: string;
	disabled?: boolean;
}

const ITEMS_TO_SHOW = 5;

export const SideFormikSelectInputWithNegative: FC<Props> = ({
	label,
	placeholder,
	onClick,
	name,
	className,
	disabled
}: Props) => {
	const [searchQuery, setSearchQuery] = useState('');

	const [, meta, helper] = useField(name);
	const { value } = meta;

	const [, negativeMeta, negativeHelper] = useField(`negative_${name}`);
	const { value: negativeValue } = negativeMeta;

	const firstFive = value?.slice(0, ITEMS_TO_SHOW);
	const firstFiveNegative = negativeValue?.slice(0, ITEMS_TO_SHOW);

	const handleSelect = (data: string) => {
		if (value?.includes(data)) {
			if (!negativeValue) {
				negativeHelper.setValue([data]);
			} else {
				negativeHelper.setValue([...negativeValue!, data]);
			}
			handleDelete(data);
			return;
		}
		if (negativeValue?.includes(data)) {
			if (!value) {
				helper.setValue([data]);
			} else {
				helper.setValue([...value!, data]);
			}
			handleDeleteNegative(data);
			return;
		}
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
	};

	// @ts-ignore
	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			//@ts-ignore
			handleSelect(e.target.value);
			setSearchQuery('');
		}
	};

	const handleClear = () => {
		helper.setValue([], true);
		negativeHelper.setValue([], true);
	};

	const handleDelete = (deleted: string) => {
		helper.setValue(value?.filter((item: string) => item !== deleted) || []);
	};

	const handleDeleteNegative = (deleted: string) => {
		negativeHelper.setValue(negativeValue?.filter((item: string) => item !== deleted) || []);
	};

	const handleSwitch = (data: string) => {
		if (!negativeValue) {
			negativeHelper.setValue([data]);
		} else {
			negativeHelper.setValue([...negativeValue!, data]);
		}
		handleDelete(data);
	};

	const handleSwitchNegative = (data: string) => {
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
		handleDeleteNegative(data);
	};

	return (
		<div className={cn(styles.wrapper, className)}>
			<div
				className={cn(styles.title, {
					[styles.titleMargin]: (value && value.length > 0) || (negativeValue && negativeValue.length > 0)
				})}>
				<Text className={styles.titleLabel}>
					{label}&nbsp;
					{((value && value.length > 0) || (negativeValue && negativeValue.length > 0)) && (
						<span>({(value ? value.length : 0) + (negativeValue ? negativeValue.length : 0)})</span>
					)}
				</Text>
				{((value && value.length > 0) || (negativeValue && negativeValue.length > 0)) && (
					<TextButton onClick={handleClear} className={styles.titleButton}>
						Clear
					</TextButton>
				)}
			</div>
			{((value && value.length > 0) || (negativeValue && negativeValue.length > 0)) && (
				<div className={styles.tagsWrapper}>
					{value && value.length > 0 && (
						<div className={styles.tags}>
							{firstFive?.map((item: string) => (
								<Tag
									key={item}
									label={item}
									onClick={() => handleDelete(item)}
									handleSwitch={() => handleSwitch(item)}
								/>
							))}
							{value && value.length > ITEMS_TO_SHOW && (
								<Tooltip
									arrow={{ arrowPointAtCenter: true }}
									title={value && value.slice(ITEMS_TO_SHOW, value.length).join(' · ')}
									placement="right">
									<button type="button" onClick={onClick} className={styles.tag}>
										<Text variant="inter/14/medium">{value.length - ITEMS_TO_SHOW} More</Text>
									</button>
								</Tooltip>
							)}
						</div>
					)}
					{negativeValue && negativeValue.length > 0 && (
						<div className={styles.tags}>
							{firstFiveNegative?.map((item: string) => (
								<Tag
									negative={true}
									className={styles.negative}
									key={item}
									label={item}
									onClick={() => handleDeleteNegative(item)}
									handleSwitch={() => handleSwitchNegative(item)}
								/>
							))}
							{negativeValue && negativeValue.length > ITEMS_TO_SHOW && (
								<Tooltip
									arrow={{ arrowPointAtCenter: true }}
									title={
										negativeValue &&
										negativeValue.slice(ITEMS_TO_SHOW, negativeValue.length).join(' · ')
									}
									placement="right">
									<button type="button" onClick={onClick} className={styles.tag}>
										<Text variant="inter/14/medium">
											{negativeValue.length - ITEMS_TO_SHOW} More
										</Text>
									</button>
								</Tooltip>
							)}
						</div>
					)}
				</div>
			)}
			<Field name={name}>
				{({ field, form, meta }: FieldProps) => (
					<Form.Item className={styles.autocomplete} shouldUpdate>
						<AutoComplete
							onKeyDown={handleEnterPress}
							value={searchQuery}
							onSelect={handleSelect}
							getPopupContainer={trigger => trigger.parentNode}
							onSearch={setSearchQuery}
							placeholder={placeholder}
							disabled={disabled}
						/>
					</Form.Item>
				)}
			</Field>
		</div>
	);
};
