import React, { FC, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Link } from 'react-router-dom';

import { CenterModal, CreditsSummary, ModalFooter } from 'shared/components';
import { Button, Checkbox, Icon, openNotification, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { useIsFree } from 'features/auth';
import { CandidatesWithCount } from 'features/csv-export';
import { isPlural } from 'shared/utils';
import { CalendlyWidget } from 'features/iframes';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	candidates: CandidatesWithCount;
	handleExport: (isFull: boolean) => void;
	isFree?: boolean;
	loading: boolean;
}

export const ExportCsvModal: FC<Props> = ({ onClose, candidates, handleExport, isFree, loading }: Props) => {
	const [isFull, setIsFull] = useState(false);
	const { data } = useGetCurrentUserQuery();

	const handleCheckbox = (e: CheckboxChangeEvent) => {
		setIsFull(e.target.checked);
	};

	// const { data } = useGetCurrentUserQuery();

	// const userHasFreePlan = useIsFree();

	const handleSubmit = async () => {
		if (data && data.credits < candidates.count) {
			openNotification({
				key: 'export-notification',
				text: 'You do not have enough credits.',
				closable: true
			});
			return;
		}
		try {
			await handleExport(isFull);

			// openNotification({
			// 	key: 'export-notification',
			// 	text: 'We’re preparing your CSV file.',
			// 	closable: true
			// });
		} catch (e) {
			console.error(e);
		}
	}

	if (data && data.credits < 1) return <CalendlyWidget handleClose={onClose} variant='search' />;

	return (
		<CenterModal onClose={onClose} icon="export" iconColor="green" title="Export contacts">
			<div className={styles.body}>
				{/* {data && userHasFreePlan && !isFree && (
					<div className={styles.upgrade}>
						<div>
							<Icon icon="info" />
							<Text variant="grotesk/14/medium" component="p" color="dark_green">
								{`You can export up to ${data.credits} contacts.`}
							</Text>
						</div>
						<Link to="/registration/plan" className={styles.upgradeLink}>
							<Text variant="inter/15/semi">Get more</Text>
						</Link>
					</div>
				)} */}
				<div className={styles.count}>
					<Icon icon="person" />
					<Text variant="inter/14/medium" color="dark_blue">
						{candidates.count} {isPlural(candidates.count) ? 'leads' : 'lead'}
					</Text>
				</div>
				{/* {isFree ? (
					<div className={styles.count}>
						<Icon icon="person" />
						<Text variant="inter/14/medium" color="dark_blue">
							{candidates.count} {isPlural(candidates.count) ? 'candidates' : 'candidate'}
						</Text>
					</div>
				) : (
					<CreditsSummary
						candidates={candidates.count}
						credits={candidates.count}
						tooltip="No credits will be charged for candidates with previously opened
				contacts."
					/>
				)} */}
				<Checkbox className={styles.checkbox} checked={isFull} onChange={handleCheckbox}>
					<Text variant="inter/14/medium">Include all fields in CSV</Text>
				</Checkbox>
				<Text  color="grey" component="p">
					By default CSV includes basic information about the lead. You can choose to include all information.{' '}
					<br />
					<br />
					After the CSV file is created, it will be downloaded automatically.
				</Text>
			</div>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>
				{/* {data && (
					<Button type="primary" disabled={!isFree && data.credits < candidates.count} onClick={handleSubmit}>
						Email CSV
					</Button>
				)} */}
				{loading ? (
					<Button type="secondary" onClick={() => {}} loading={loading} disabled={true}>
						Preparing
					</Button>
				) : (<Button type="primary" onClick={handleSubmit}>
				Download
			</Button>)}
			</ModalFooter>
		</CenterModal>
	);
};
