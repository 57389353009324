import React, { FC, useEffect, useMemo, useState } from 'react';
import { ConfigProvider } from 'antd';
import cn from 'classnames';

import { Icon, Text, Tooltip } from 'shared/components/ui';
import { ContactModal, ProfileVariant, useOpenContacts } from 'features/candidate';
import { useGetCandidateProfileQuery } from 'services';
// import { Analytics } from 'features/analytics';
import { ProgAICandidate } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
	variant: ProfileVariant;
	profileId?: number;
}

export const CandidateContacts: FC<Props> = ({ data, variant, profileId }: Props) => {
	const id = variant === 'projects' ? data.prog_ai_id : data.id;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [skip, setSkip] = useState(true);

	const { isFetching } = useGetCandidateProfileQuery(id!, { skip });

	const [, { isLoading }] = useOpenContacts(variant);

	useEffect(() => {
		variant === 'search' && isLoading && setSkip(false);
	}, [isLoading]);

	const primaryEmail = data.primary_email;

	const emailsWithoutPrimary = useMemo(
		() => (data.emails_cleaned || []).filter(email => email.toLowerCase() !== primaryEmail?.toLowerCase()),
		[data]
	);

	// const handleGithubClick = () => {
	// 	Analytics.trackGithubClick(id!, data.github_url!);
	// };

	const handleLinkedinClick = () => {
		// Analytics.trackLinkedinClick(id!, data.linkedin_url!);
	};

	// const handleTwitterClick = () => {
	// 	Analytics.trackTwitterClick(id!, data.twitter_url!);
	// };

	const handleEmail = (email: string) => {
		// Analytics.trackEmailCopy(id!, email);
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handlePrimaryEmail = () => {
		handleEmail(primaryEmail!);
	};

	if (data.emails_cleaned)
		return (
			<div className={cn(styles.contacts, styles.contactsOpen)}>
				{(primaryEmail || emailsWithoutPrimary.length > 0) && (
					<div className={styles.contactsMail}>
						<Icon icon="mail" />
						<ul className={styles.contactsList}>
							{primaryEmail && (
								<li key={primaryEmail}>
									<a
										className={styles.contactsListPrimary}
										href={`mailto:${primaryEmail}`}
										onCopy={handlePrimaryEmail}
										onClick={handlePrimaryEmail}>
										<Text variant="inter/14/medium" color="light_blue">
											{primaryEmail}
										</Text>
									</a>
								</li>
							)}
							{emailsWithoutPrimary.map(item => (
								<li key={item}>
									<a
										href={`mailto:${item}`}
										onCopy={() => handleEmail(item)}
										onClick={() => handleEmail(item)}>
										<Text variant="inter/14/medium" color="light_blue">
											{item}
										</Text>
									</a>
								</li>
							))}
						</ul>
					</div>
				)}
				{data.phoneNumbers && (
					<div className={styles.contactsMail}>
						<Icon icon="phone" />
						<Text variant="inter/14/medium">{data.phoneNumbers}</Text>
					</div>
				)}
				<div className={styles.contactsSocial}>
					{/* <a
						onClick={handleGithubClick}
						className={styles.iconGithub}
						href={`https://github.com/${data.github_handle}`}
						target="_blank"
						rel="noreferrer">
						<Icon icon="github-logo" size={20} />
					</a> */}
					{data.externalProfileUrl && (
						<a
							onClick={handleLinkedinClick}
							className={styles.iconLinkedin}
							href={data.externalProfileUrl}
							target="_blank"
							rel="noreferrer">
							<Icon icon="linkedin-logo" size={20} />
						</a>
					)}
					{/* {data.twitter_url && (
						<a
							onClick={handleTwitterClick}
							className={styles.iconTwitter}
							href={data.twitter_url}
							target="_blank"
							rel="noreferrer">
							<Icon icon="twitter-logo" size={20} />
						</a>
					)} */}
				</div>
			</div>
		);

	return (
		<div className={cn(styles.contacts, styles.contactsOpen)}>
			<ConfigProvider
				theme={{
					components: {
						Tooltip: { colorBgDefault: '#e6f6f0', paddingSM: 24, paddingXS: 12, zIndexPopup: 10000 }
					}
				}}>
				<Tooltip
					arrow={false}
					placement="bottomRight"
					align={{ offset: [0, 0] }}
					title={
						<Text variant="inter/13/regular" color="dark_green" className={styles.tooltip} paragraph>
							<Text variant="inter/14/semi" color="dark_green">
								Get contact
							</Text>{' '}
							to unlock the contact emails and phone numbers. It requires{' '}
							<span className={styles.tooltipCredits}>1 credit</span>
						</Text>
					}>
					<button className={styles.mail} onClick={openModal}>
						<Icon icon="mail" className={styles.contactsMail} />
						<Text variant="inter/14/medium" color="dark_grey" className={styles.mailBlurred}>
							user@mail.com
						</Text>
					</button>
				</Tooltip>
			</ConfigProvider>
			<div className={styles.contactsSocial}>
				{data.externalProfileUrl && (
					<a
						onClick={handleLinkedinClick}
						className={styles.iconLinkedin}
						href={data.externalProfileUrl}
						target="_blank"
						rel="noreferrer">
						<Icon icon="linkedin-logo" size={20} />
					</a>
				)}
			</div>
			{isModalOpen && (
				<ContactModal
					onClose={closeModal}
					id={profileId!}
					variant={variant === 'search' ? 'profile' : variant}
				/>
			)}
		</div>
	);

	// return (
	// 	<>
	// 		<ConfigProvider
	// 			theme={{
	// 				components: {
	// 					Tooltip: { colorBgDefault: '#e6f6f0', paddingSM: 24, paddingXS: 12, zIndexPopup: 10000 }
	// 				}
	// 			}}>
	// 			<Tooltip
	// 				arrow={false}
	// 				placement="bottomRight"
	// 				align={{ offset: [110, -40] }}
	// 				title={
	// 					<Text variant="inter/13/regular" color="dark_green" className={styles.tooltip} paragraph>
	// 						<Text variant="inter/14/semi" color="dark_green">
	// 							Get contact
	// 						</Text>{' '}
	// 						to unlock the contact emails and phone numbers. It requires{' '}
	// 						<span className={styles.tooltipCredits}>1 credit</span>
	// 					</Text>
	// 				}>
	// 				<button className={styles.mail} onClick={openModal}>
	// 					{/* <div> */}
	// 					<Icon icon="mail" />
	// 					<Text variant="inter/14/medium" color="dark_grey" className={styles.mailBlurred}>
	// 						user@mail.com
	// 					</Text>
	// 					{/* </div> */}
	// 				</button>
	// 			</Tooltip>
	// 		</ConfigProvider>
	// 		{isModalOpen && (
	// 			<ContactModal
	// 				onClose={closeModal}
	// 				id={profileId!}
	// 				variant={variant === 'search' ? 'profile' : variant}
	// 			/>
	// 		)}
	// 	</>
	// );

	// return (
	// 	<>
	// 		<ConfigProvider
	// 			theme={{
	// 				components: {
	// 					Tooltip: { colorBgDefault: '#e6f6f0', paddingSM: 24, paddingXS: 12, zIndexPopup: 10000 }
	// 				}
	// 			}}>
	// 			<Tooltip
	// 				arrow={false}
	// 				placement="bottomRight"
	// 				align={{ offset: [110, -40] }}
	// 				title={
	// 					<Text variant="inter/13/regular" color="dark_green" className={styles.tooltip} paragraph>
	// 						<Text variant="inter/14/semi" color="dark_green">
	// 							Get contact
	// 						</Text>{' '}
	// 						to unlock the contact email and social links. It requires{' '}
	// 						<span className={styles.tooltipCredits}>1 credit</span>
	// 					</Text>
	// 				}>
	// 				<button className={styles.contacts} onClick={openModal}>
	// 					<div className={cn(styles.contactsMail, styles.contactsMailClosed)}>
	// 						<Icon icon="mail" />
	// 						<Text variant="inter/14/medium" color="dark_grey">
	// 							{isFetching || isLoading ? 'Loading...' : '****mail.com'}
	// 						</Text>
	// 					</div>
	// 					<div className={styles.contactsSocial}>
	// 						<Icon icon="github-logo" size={24} />
	// 						<Icon icon="linkedin-logo" size={24} />
	// 						<Icon icon="twitter-logo" size={24} />
	// 					</div>
	// 				</button>
	// 			</Tooltip>
	// 		</ConfigProvider>
	// 		{isModalOpen && <ContactModal onClose={closeModal} id={id!} variant={variant} />}
	// 	</>
	// );
};
