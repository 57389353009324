import { FC, useState } from 'react';

import LemlistLogo from 'shared/assets/svg-icons/lemlist-logo.svg';
import SourceWhaleLogo from 'shared/assets/svg-icons/source-whale-logo.svg';
import { CenterModal, CreditsSummary, ModalFooter } from 'shared/components';
import { ServiceEnum, TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { Button, Text, openNotification } from 'shared/components/ui';
import { useSubmitCampaignMutation } from 'services';
// import { Analytics } from 'features/analytics';
import { PushConfirmModalState } from 'features/integrations/models';

import { CampaignItem } from '../CampaignItem';

import styles from './index.module.scss';

type Props = PushConfirmModalState & {
	// projectId: number;
	candidates: TopIdsWithFiltersCandidates & {
		count: number;
	};
	onClose: () => void;
	onBack: () => void;
};

const serviceName = {
	[ServiceEnum.LEMLIST]: 'Lemlist',
	[ServiceEnum.SOURCEWHALE]: 'SourceWhale'
};

const serviceLogo = {
	[ServiceEnum.LEMLIST]: LemlistLogo,
	[ServiceEnum.SOURCEWHALE]: SourceWhaleLogo
};

export const PushConfirmModal: FC<Props> = ({ service, result, candidates, onBack, onClose }: Props) => {
	const [submitCampaign, state] = useSubmitCampaignMutation();
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = async () => {
		setError(null);

		try {
			let res: { url: string } | null = null;

			if (service === ServiceEnum.LEMLIST) {

				if (candidates.profile_id_list && candidates.profile_id_list.length > 3000) {
					openNotification({ text: 'You can export up to 3000 leads', closable: true });
					return;
				}
				if (candidates.limit && candidates.limit > 3000) {
					openNotification({ text: 'You can export up to 3000 leads', closable: true });
					return;
				}

				if (candidates.profile_id_list && candidates.profile_id_list.length > 0) {
				res = await submitCampaign({
					// top: candidates.top,
					profile_id_list: candidates.profile_id_list,
					// excluded_ids: candidates.excluded_ids,
					// project_id: projectId,
					service,
					campaign: result.campaignId
				}).unwrap(); }
				else {
					res = await submitCampaign({
					// top: candidates.top,
					filters: candidates.filters,
					limit: candidates.limit,
					// excluded_ids: candidates.excluded_ids,
					// project_id: projectId,
					service,
					campaign: result.campaignId
				}).unwrap();
				}
			}

			if (res?.url) {
				window.open(res.url, '_blank')?.focus();
			}

			// Analytics.trackPushToOutreach({
			// 	service,
			// 	candidatesCount: candidates.count
			// });

			openNotification({
				key: 'push-confirm',
				text: `${candidates.count} candidates exported to ${serviceName[service]}. Go to ${serviceName[service]} to continue`,
				closable: true
			});

			onClose();
		} catch (e: any) {
			const message = e?.data?.service?.[0] || e?.data?.errors;

			setError(typeof message === 'string' ? message : 'Error occurred, please try again or contact support');
		}
	};

	return (
		<CenterModal width={520} className={styles.root} onClose={onClose}>
			<div className={styles.header}>
				<div className={styles.logo}>
					<img src={serviceLogo[service]} alt="Lemlist logo" />
				</div>
				<Text variant="inter/20/medium" color="dark_blue">
					Export contacts to {serviceName[service]}
				</Text>
			</div>

			<div>
				{service === ServiceEnum.LEMLIST ? (
					<CampaignItem title={result.campaignName} subtitle={result.labels?.join(', ')} />
				) : (
					<div>
						{!result.projects && !result.campaign ? (
							<ul className={styles.list}>
								<li>
									<Text variant="inter/14/regular" color="grey">
										Export to
									</Text>
								</li>
								<li>
									<Text variant="inter/14/regular" color="black">
										Contacts
									</Text>
								</li>
							</ul>
						) : null}
						{result.projects && result.projects?.length > 0 ? (
							<ul className={styles.list}>
								<li>
									<Text variant="inter/14/regular" color="grey">
										Projects {result.projects.length > 1 ? <>({result.projects.length})</> : null}
									</Text>
								</li>
								{result.projects.map(item => (
									<li>
										<Text variant="inter/14/regular" color="black">
											{item.projectName}
										</Text>
									</li>
								))}
							</ul>
						) : null}
						{result.campaign ? (
							<ul className={styles.list}>
								<li>
									<Text variant="inter/14/regular" color="grey">
										Campaign
									</Text>
								</li>
								<li>
									<Text variant="inter/14/regular" color="black">
										{result.campaign.campaignName}
									</Text>
								</li>
							</ul>
						) : null}
					</div>
				)}

				<CreditsSummary
					className={styles.summary}
					candidates={candidates.count}
					credits={candidates.count}
					tooltip="No credits will be charged for candidates with previously opened contacts."
				/>
			</div>

			{error ? (
				<Text variant="inter/14/regular" color="red">
					{error}
				</Text>
			) : null}

			<div className={styles.actions}>
				<Button disabled={state.isLoading} type="ghost" onClick={onBack}>
					Back
				</Button>
				<ModalFooter>
					<Button disabled={state.isLoading} type="ghost" onClick={onClose}>
						Cancel
					</Button>
					<Button disabled={state.isLoading} loading={state.isLoading} type="primary" onClick={handleSubmit}>
						{state.isLoading ? 'Exporting...' : 'Export'}
					</Button>
				</ModalFooter>
			</div>
		</CenterModal>
	);
};
