import { memo, useState } from 'react';

import { SearchHistory, SearchParams } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';
import { Td, Tr } from 'features/table';
import { formatDate, formatTime, MONTH_DAY_FORMAT } from 'shared/utils';
import { useSearch } from 'features/search/hooks';
import { normalizeHeadcount } from 'features/search/utils';

import styles from './index.module.scss';

interface Props {
	searchItem: SearchHistory;
}

const formatNumberWithCommas = (number: number) => {
	if (number > 300000) return '300,000+';
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const formatSearchFilters = (values: SearchParams) => {
    const filters = {...values};
    if ('headcount' in filters) {
        filters['headcount'] = normalizeHeadcount(filters['headcount']);
    }

    let formattedFilters = '';
    if ('job_titles' in filters) {
        formattedFilters += `<span class="${styles.span}">Functional Area</span>: ${filters['job_titles']?.join(', ')}; `; 
    }
    if ('title_keywords' in filters) {
        formattedFilters += `<span class="${styles.span}">Job Title Keywords</span>: ${filters['title_keywords']?.join(', ')}; `; 
    }
    if ('industries' in filters) {
        formattedFilters += `<span class="${styles.span}">Industry</span>: ${filters['industries']?.join(', ')}; `; 
    }
    if ('keywords' in filters) {
        formattedFilters += `<span class="${styles.span}">Company Keywords</span>: ${filters['keywords']?.join(', ')}; `; 
    }
    if ('lookalike' in filters) {
        formattedFilters += `<span class="${styles.span}">Similar Companies</span>: ${filters['lookalike']}; `; 
    }
    if ('bombora_surge_list_topic' in filters) {
        formattedFilters += `<span class="${styles.span}">Buyers Intent</span>: ${filters['bombora_surge_list_topic']?.join(', ')}; `; 
    }
    if ('technography' in filters) {
        formattedFilters += `<span class="${styles.span}">Technographics</span>: ${filters['technography']?.join(', ')}; `; 
    }
    if ('locations' in filters) {
        formattedFilters += `<span class="${styles.span}">City or State</span>: ${filters['locations']?.join(', ')}; `; 
    }
    if ('subregions' in filters) {
        formattedFilters += `<span class="${styles.span}">Subregion</span>: ${filters['subregions']?.join(', ')}; `; 
    }
    if ('regions' in filters) {
        formattedFilters += `<span class="${styles.span}">Region</span>: ${filters['regions']?.join(', ')}; `; 
    }
    if ('countries' in filters) {
        formattedFilters += `<span class="${styles.span}">Country</span>: ${filters['countries']?.join(', ')}; `; 
    }
    if ('headcount' in filters) {
        formattedFilters += `<span class="${styles.span}">Headcount</span>: ${filters['headcount']?.join(', ')}; `; 
    }
    if ('last_funding_date' in filters) {
        formattedFilters += `<span class="${styles.span}">Last Funding Date</span>: ${filters['last_funding_date']}; `; 
    }
    // if ('first_name' in filters) {
    //     formattedFilters += `<span class="${styles.span}">First Name</span>: ${filters['first_name']}; `; 
    // }
    // if ('last_name' in filters) {
    //     formattedFilters += `<span class="${styles.span}">Last Name</span>: ${filters['last_name']}; `; 
    // }
    if ('linkedin_url' in filters) {
        formattedFilters += `<span class="${styles.span}">Person Linkedin URL</span>: ${filters['linkedin_url']}; `; 
    }
    if ('companies' in filters) {
        formattedFilters += `<span class="${styles.span}">Company</span>: ${filters['companies']?.join(', ')}; `; 
    }
    if ('total_funding_amount' in filters) {
        formattedFilters += `<span class="${styles.span}">Total Funding Amount</span>: ${filters['total_funding_amount']?.join(', ')}; `; 
    }

    return formattedFilters;
}

export const SearchRow = memo(({ searchItem }: Props) => {
    const search = useSearch();

    const handleSearch = () => {
        const values = {...searchItem.filters};
        if ('headcount' in values) {
            values['headcount'] = normalizeHeadcount(values['headcount']);
        }
        search(values, null, true);
    }

	return (
		<>
			<Tr className={styles.profile}>
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Td className={styles.cell} width="144px">
						<Text className={styles.title} variant="inter/14/regular" component="p" color="grey">
							{formatDate(searchItem.created_at, MONTH_DAY_FORMAT)}, {formatTime(searchItem.created_at)}
						</Text>
					</Td>
                    <Td className={styles.cell} width="500px">
						<Text className={styles.title} variant="inter/14/regular">
                            <span dangerouslySetInnerHTML={{ __html: formatSearchFilters(searchItem.filters) }} />
						</Text>
					</Td>
					<Td className={styles.cell} width="164px">
						<Text className={styles.title} variant="inter/14/regular" component="p">
							{formatNumberWithCommas(searchItem.leads_number)} {searchItem.leads_number === 1 ? 'lead' : 'leads'}
						</Text>
					</Td>
                    <Td className={styles.cell} width="180px">
						<div className={styles.search} onClick={handleSearch}>
							<Text className={styles.title} variant="inter/14/regular" component="p">
								<span style={{textDecoration: "underline"}}>Search</span>
							</Text>
							<Icon icon="external" size={20} />
						</div>
					</Td>
				</div>
			</Tr>
		</>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return oldProps.searchItem === newProps.searchItem;
}
