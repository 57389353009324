import { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { Avatar, ConfigProvider } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { Icon, Tooltip, Dropdown, GradientBox, Text } from 'shared/components/ui';
import { getFullName, getInitials } from 'shared/utils';
import { useAppSelector } from 'store/redux-hooks';
import { clearTokens, useGetCurrentUserQuery, useLogoutUserMutation } from 'services';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const Header: FC<Props> = ({ className }: Props) => {
	const { title, isOpenCandidateModal } = useAppSelector(state => state.header);
	const location = useLocation();
	const [currentPath, setCurrentPath] = useState(location.pathname);

	const { data } = useGetCurrentUserQuery();
	const [logoutUser] = useLogoutUserMutation();

	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location.pathname]);

	const handleLogout = () => {
		logoutUser().then(() => {
			clearTokens();
		});
	};

	const items = [
		{
			label: (
				<Link to="/profile" className={styles.dropdownImportant}>
					{getFullName(data)}
				</Link>
			),
			key: '0'
		},
		{
			label: (
				<Link to="/registration/plan" className={styles.dropdownImportant}>
					Upgrade
				</Link>
			),
			key: '1'
		},
		{
			label: (
				<Link to="/login" onClick={handleLogout}>
					Logout
				</Link>
			),
			key: '2'
		}
	];

	return (
		<header
			className={cn(styles.header, className, {
				[styles.headerResults]: currentPath === '/search/results',
				[styles.headerSearch]: currentPath === '/search' || currentPath === '/history',
				[styles.headerPlan]: currentPath === '/registration/plan',
				[styles.headerOpenCandidateModal]: isOpenCandidateModal
			})}>
			<Text variant="grotesk/16/bold" component="h2">
				{title}
			</Text>
			<div className={styles.profileInfo}>
				<ConfigProvider theme={{ components: { Tooltip: { paddingXS: 0, paddingSM: 0, sizePopupArrow: 0 } } }}>
					<Tooltip
						placement="bottomRight"
						align={{ offset: [0, 0] }}
						overlayClassName={styles.tooltipOverlay}
						title={
							<div className={styles.tooltipContainer}>
								<Text
									variant="inter/14/semi"
									component="p"
									className={cn(styles.tooltipText, styles.tooltipTitle)}>
									These are your remaining credits
								</Text>
								<Text component="p" color="grey" className={cn(styles.tooltipText)}>
									1 credit is used when you reveal or export profiles contacts.
								</Text>
							</div>
						}>
						{data && (
							<div className={styles.profileBalance}>
								<Icon icon="credits" /> <Text color="dark_green">{data.credits}</Text>
							</div>
						)}
					</Tooltip>
				</ConfigProvider>
				<Dropdown menu={{ items }} trigger={['click']} overlayClassName={styles.profileAccountOverlay}>
					<div className={styles.profileAccount}>
						<GradientBox className={styles.gradientBox} backgroundClassName={styles.gradientBoxBefore}>
							<Avatar src={data?.avatar}>{getInitials(data)}</Avatar>
						</GradientBox>
						<Icon className={styles.profileAccountDropdown} icon="arrow-down" />
					</div>
				</Dropdown>
			</div>
		</header>
	);
};
