/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntervalEnum {
    // WEEKS = 'weeks',
    MONTH = 'month',
    // YEAR = 'year',
    // FOREVER = 'forever'
}
