import { useMemo, useState } from 'react';
import cn from 'classnames';

import Check from 'shared/assets/svg-icons/check-gradient.svg';
import { Button, GradientBox, Icon, Text } from 'shared/components/ui';
import { Tabs } from 'shared/components/Tabs';
import { IntervalEnum, Plan } from 'shared/generated-models';
import { currencyFormatter, MURAENA_EMAIL } from 'shared/utils';
import { BillingPlan } from 'shared/models';
import { useGetCurrentUserQuery, useGetPlansQuery } from 'services';
import { CardButton, CardSkeleton } from 'features/subscription';

import styles from './index.module.scss';

export const SubscriptionCards = () => {
	const { data, isFetching } = useGetPlansQuery();
	const { data: user } = useGetCurrentUserQuery();

	const [interval, setInterval] = useState(IntervalEnum.MONTH);

	// const [annually, monthly, trial] = useMemo(() => {
	// 	if (data) {
	// 		const annually: Plan[] = [];
	// 		const monthly: Plan[] = [];
	// 		let trial: Plan | null = null;

	// 		data.forEach(item => {
	// 			if (item.interval === IntervalEnum.YEAR) {
	// 				annually.push(item);
	// 			} else if (item.interval === IntervalEnum.MONTH) {
	// 				monthly.push(item);
	// 			} else if (item.billing_plan === BillingPlan.FREE) {
	// 				trial = item;
	// 			}
	// 		});

	// 		return [annually, monthly, trial as any as Plan];
	// 	} else {
	// 		return [[], [], null];
	// 	}
	// }, [data]);

	const [monthly] = useMemo(() => {
		if (data) {
			const monthly: Plan[] = [];
			// let trial: Plan | null = null;
			let free: Plan | null = null;

			data.forEach(item => {
				if (item.interval === IntervalEnum.MONTH) {
					monthly.push(item);
				}
			});

			return [monthly];
		} else {
			return [[], [], null];
		}
	}, [data]);

	const plans: Record<IntervalEnum, Plan[]> = {
		// [IntervalEnum.YEAR]: annually,
		[IntervalEnum.MONTH]: monthly
	};

	if (isFetching)
		return (
			<section className={styles.cards}>
				{[0, 1, 2].map(item => (
					<CardSkeleton key={item} />
				))}
			</section>
		);

	return (
		<div className={styles.cardsWrapper}>
			{/* <div className={styles.tabsWrapper}>
				<div className={styles.tabsSave}>
					<Text component="p" color="white">
						Save 10%
					</Text>
					<Icon className={styles.tabsArrow} icon="small-arrow" />
				</div>
				<Tabs
					value={interval}
					onChange={setInterval}
					items={[
						{ value: IntervalEnum.YEAR, label: 'Annually' },
						{ value: IntervalEnum.MONTH, label: 'Monthly' }
					]}
				/>
			</div> */}
			<section className={styles.cards}>
				{/* <GradientBox className={styles.cardGradient} backgroundClassName={styles.cardGradientBefore}>
					<div className={styles.card}>
						<div className={styles.cardTop}>
							<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
								Free
							</Text>
							<Text component="p" variant="grotesk/16/regular" color="grey" className={styles.cardPrice}>
								<Text variant="grotesk/32/bold">Trial</Text>
								<span>/ 14 days</span>
							</Text>
						</div>
						<Text component="p" variant="grotesk/16/regular" className={styles.cardDescription}>
							Try our powerful search&nbsp;to source up&nbsp;to&nbsp;10&nbsp;candidates. Export data into
							CSV to&nbsp;your email and start hiring.
						</Text>
						<ul className={styles.cardPerks}>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="grotesk/16/regular" component="p">
									Unlimited search over 60M candidate profiles
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="grotesk/16/regular" component="p">
									<Text variant="grotesk/16/bold">{free?.credits}</Text> credits (Export CSV)
								</Text>
							</li>
						</ul>
						<CardButton billingPlan={BillingPlan.FREE} />
					</div>
				</GradientBox> */}

				{plans[interval]
					.sort((a, b) => a.credits - b.credits)
					.map(plan => (
						<GradientBox
							key={plan.id}
							className={cn(styles.cardGradient, {
								[styles.cardPro]: plan.billing_plan === BillingPlan.BUSINESS
							})}
							backgroundClassName={styles.cardGradientBefore}>
							<div className={styles.card}>
								<div className={styles.cardTop}>
									<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
										{plan.billing_plan}
									</Text>
									<Text
										component="p"
										variant="grotesk/16/regular"
										color="grey"
										className={styles.cardPrice}>
										<Text variant="grotesk/32/bold">
											{currencyFormatter.format(plan.amount / 100)}
										</Text>
										<span>/ {plan.interval === IntervalEnum.MONTH ? ' monthly' : ' yearly'}</span>
									</Text>
								</div>
								{/* {plan.billing_plan === BillingPlan.BASIC ? (
									<Text component="p" variant="grotesk/16/regular" className={styles.cardDescription}>
										Perfect plan for startups or agencies who are looking to hire a few engineers.
										AI search, projects, bulk export to CSV included.
									</Text>
								) : (
									<Text component="p" variant="grotesk/16/regular" className={styles.cardDescription}>
										Plan for bigger teams that includes 2000 credits per seat. AI search, projects,
										bulk export to CSV, integrations.
									</Text>
								)} */}
								<div className={styles.cardPerks}>
									<ul>
										<li>
											<img src={Check} alt="check icon" />
											<Text variant="grotesk/16/regular" component="p">
												<span>{plan.credits}</span> export credits / month
											</Text>
										</li>
										<li>
											<img src={Check} alt="check icon" />
											<Text variant="grotesk/16/regular" component="p">
												Technographics data
											</Text>
										</li>
										<li>
											<img src={Check} alt="check icon" />
											<Text variant="grotesk/16/regular" component="p">
												Buyers Intent insights
											</Text>
										</li>
										<li>
											<img src={Check} alt="check icon" />
											<Text variant="grotesk/16/regular" component="p">
												Unlimited AI search
											</Text>
										</li>
										<li>
											<img src={Check} alt="check icon" />
											<Text variant="grotesk/16/regular" component="p">
												Export to CSV / Pipedrive / lemlist
											</Text>
										</li>
										{plan.billing_plan === BillingPlan.BASIC && (
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													24/7 email and chat support
												</Text>
											</li>
										)}
										{plan.billing_plan === BillingPlan.BUSINESS && (
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													Custom leads export for your ICP
												</Text>
											</li>
										)}
										{plan.billing_plan === BillingPlan.BUSINESS && (
											<li>
												<img src={Check} alt="check icon" />
												<Text variant="grotesk/16/regular" component="p">
													API access
												</Text>
											</li>
										)}
										{/* {plan.billing_plan === BillingPlan.BASIC ? (
											<>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														Unlimited search over 60M candidate profiles
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														<span>{plan.credits}</span> credits / monthly
													</Text>
												</li>
											</>
										) : (
											<>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														Unlimited search over 60M candidate profiles
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="grotesk/16/regular" component="p">
														<span>{plan.credits}</span> credits / monthly
													</Text>
												</li>
											</>
										)} */}
									</ul>
								</div>

								<CardButton
									className={plan.billing_plan !== BillingPlan.BUSINESS ? styles.bottomButton : ''}
									billingPlan={plan.billing_plan}
									priceId={plan.id}
								/>
							</div>
						</GradientBox>
					))}

				{/* <GradientBox className={styles.cardGradient} backgroundClassName={styles.cardGradientBefore}>
					<div className={styles.card}>
						<div className={styles.cardTop}>
							<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
								Enterprise
							</Text>
							<Text
								component="p"
								variant="grotesk/16/regular"
								color="grey"
								className={cn(styles.cardPrice, styles.cardPriceCustom)}>
								<Text variant="grotesk/32/bold">Custom</Text>
							</Text>
						</div>
						<Text component="p" variant="grotesk/16/regular" className={styles.cardDescription}>
							Get a custom plan for your team. Integration with your ATS, and access to volume discount.
							AI search and other features included.
						</Text>
						<ul className={styles.cardPerks}>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="grotesk/16/regular" component="p">
									Unlimited search over 60M candidate profiles
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="grotesk/16/regular" component="p">
									Pooled credits
								</Text>
							</li>
						</ul>
						<Button
							block
							component={user?.billing_plan === BillingPlan.ENTERPRISE ? 'button' : 'a'}
							href={`mailto:${MURAENA_EMAIL}`}
							disabled={user?.billing_plan === BillingPlan.ENTERPRISE}>
							{user?.billing_plan === BillingPlan.ENTERPRISE ? 'Current plan' : 'Talk to sales'}
						</Button>
					</div>
				</GradientBox> */}
			</section>
			{/* {user?.billing_plan !== BillingPlan.FREE && (
				<Text component="p" variant="grotesk/20/regular" color="grey" className={styles.contact}>
					To change plan or unsubscribe <a href={`mailto:${MURAENA_EMAIL}`}>Contact&nbsp;us</a>
				</Text>
			)} */}
		</div>
	);
};
