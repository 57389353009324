import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import {
	Login,
	PaymentFail,
	PaymentSuccess,
	ProfileAccount,
	ProfileSubscription,
	RegistrationLayout,
	RegistrationForm,
	RegistrationPlan,
	Results,
	Search,
	History,
	SharedCandidate,
	LoginError,
	ProjectsAcquired,
	ProjectCandidates,
	Projects,
	Integrations
} from 'pages';

import { ProfileLayout, MainLayout, ProjectsLayout } from 'layouts';
import { ErrorBoundary } from 'features/error-boundary';
import { MobileScreen, useIsMobile } from 'features/mobile';

import { ProtectedRoute } from './ProtectedRoute';
import { ProtectedExpiredRoute } from './ProtectedExpiredRoute';
import { SentryBrowserRouter } from './SentryBrowserRouter';
import { Landing } from 'pages/Landing';
import { LemlistPage } from 'pages/IntegrationsPages/LemlistPage';
import { PipedrivePage } from 'pages/IntegrationsPages/PipedrivePage';
import { PrivacyPolicy } from 'pages/Policy/Privacy';
import { Hubspot } from 'pages/Policy/Hubspot';
import { Terms } from 'pages/Policy/Terms';
import { Optout } from 'pages/IntegrationsPages/Optout';
import { PricingPage } from 'pages/PricingPage';

const routerMobil = createBrowserRouter([
	{
		path: 'pricing',
		element: <PricingPage />
	},
	{
		path: 'optout',
		element: <Optout />
	},
	{
		path: 'pipedrive',
		element: <PipedrivePage />
	},
	{
		path: 'lemlist',
		element: <LemlistPage />
	},
	{
		path: 'privacy',
		element: <PrivacyPolicy />
	},
	{
		path: 'terms',
		element: <Terms />
	},
	{
		path: 'landing',
		element: <Landing />
	},
	{
		path: 'login',
		element: <Login />
	},
	{
		path: '',
		element: <MobileScreen />,
		errorElement: <MobileScreen />
	}
]);

const routerHome = createBrowserRouter([
	{
		path: 'pricing',
		element: <PricingPage />
	},
	{
		path: 'optout',
		element: <Optout />
	},
	{
		path: 'pipedrive',
		element: <PipedrivePage />
	},
	{
		path: 'lemlist',
		element: <LemlistPage />
	},
	{
		path: 'privacy',
		element: <PrivacyPolicy />
	},
	{
		path: 'terms',
		element: <Terms />
	},
	{
		path: 'hubspot',
		element: <Hubspot />
	},
	{
		path: '',
		element: <Landing />
	}
]);

const router = createBrowserRouter([
	{
		path: 'pricing',
		element: <PricingPage />
	},
	{
		path: 'optout',
		element: <Optout />
	},
	{
		path: 'pipedrive',
		element: <PipedrivePage />
	},
	{
		path: 'lemlist',
		element: <LemlistPage />
	},
	{
		path: 'privacy',
		element: <PrivacyPolicy />
	},
	{
		path: 'terms',
		element: <Terms />
	},
	{
		path: 'hubspot',
		element: <Hubspot />
	},
	{
		path: 'landing',
		element: <Landing />
	},
	{
		path: 'login',
		element: <Login />
	},
	{
		path: 'login-error',
		element: <LoginError />
	},
	{ path: 'candidates/:id/share', element: <SharedCandidate /> },
	{
		path: '',
		element: <ProtectedRoute />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: 'registration',
				element: <RegistrationLayout />,
				children: [
					{
						path: 'form',
						element: <RegistrationForm />
					},
					// {
					// 	path: 'payment-success',
					// 	element: <PaymentSuccess />
					// },
					{
						path: 'payment-fail',
						element: <PaymentFail />
					}
				]
			},
			{
				path: 'payment-success',
				element: <RegistrationLayout />,
				children: [{ path: '', element: <PaymentSuccess /> }]
			},
			{
				path: '',
				element: <MainLayout />,
				children: [
					{
						path: '',
						element: <Navigate to="search" replace />
					},
					{
						path: 'integrations',
						element: <Integrations />
					},
					{
						path: 'search',
						element: <Search />
					},
					{
						path: 'history',
						element: <History />
					},
					// {
					// 	path: 'projects',
					// 	element: <ProtectedExpiredRoute />,
					// 	children: [
					// 		{
					// 			path: '',
					// 			element: <ProjectsLayout />,
					// 			children: [
					// 				{
					// 					path: '',
					// 					element: <Navigate to="list" replace />
					// 				},
					// 				{
					// 					path: 'list',
					// 					element: <Projects />
					// 				},
					// 				{
					// 					path: 'acquired',
					// 					element: <ProjectsAcquired />
					// 				},
					// 				{
					// 					path: ':id',
					// 					children: [
					// 						{
					// 							path: '',
					// 							element: <Navigate to="candidates" replace />
					// 						},
					// 						{
					// 							path: 'candidates',
					// 							element: <ProjectCandidates />
					// 						}
					// 					]
					// 				}
					// 			]
					// 		}
					// 	]
					// },
					{
						path: 'registration/plan',
						element: <ProtectedRoute />,
						children: [{ path: '', element: <RegistrationPlan /> }]
					},
					{
						path: 'search/results',
						element: <ProtectedExpiredRoute />,
						children: [{ path: '', element: <Results /> }]
					},
					{
						path: 'profile',
						element: <ProtectedExpiredRoute />,
						children: [
							{
								path: '',
								element: <ProfileLayout />,
								children: [
									{
										path: '',
										element: <Navigate to="account" replace />
									},
									{
										path: 'account',
										element: <ProfileAccount />
									},
									{
										path: 'subscription',
										element: <ProfileSubscription />
									}
								]
							}
						]
					}
				]
			}
		]
	}
]);

export const Router = () => {
	// const isMobile = useIsMobile();
	const isMobile = false;
	const domain = window.location.hostname;
	console.log(domain);

	if (domain === 'muraena.ai') {
		return <RouterProvider router={routerHome} />;
	} else if (domain === 'app.muraena.ai' || domain === 'localhost') {
		return <RouterProvider router={isMobile ? routerMobil : router} />;
	}

	return <RouterProvider router={router} />;
};
