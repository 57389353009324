import { memo, useState } from 'react';
import { Checkbox } from 'antd';
import cn from 'classnames';

import { CsvExport } from 'shared/generated-models';
import { CandidateProfileAvatar, ProfileCompany, ProfileEmail, ProfileName } from 'shared/components';
import { ContactModal } from 'features/candidate';
import { getProfileInitials } from 'shared/utils';
import { Icon, Text } from 'shared/components/ui';
import { Td, Tr } from 'features/table';
import { formatDate, formatTime, MONTH_DAY_FORMAT } from 'shared/utils';
import { useLazyGetCsvFileQuery } from 'services';
import { openNotification } from 'shared/components/ui';
import { useSearch } from 'features/search/hooks';
import { normalizeHeadcount } from 'features/search/utils';

import styles from './index.module.scss';

interface Props {
	csv: CsvExport;
}

function formatNumberWithCommas(number: number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const formatStatus = (status: string) => {
	switch (status) {
		case 'done':
			return 'Completed';
		case 'pending':
			return 'In progress';
		case 'failed':
			return 'Failed';
	}
};

const appendTimestampToFilename = (baseName: string, timestamp: string, extension: string) => {
	const formattedTimestamp = timestamp.slice(0, 19).replace(/[:.-]/g, '_');
	return `${baseName}_${formattedTimestamp}.${extension}`;
};

export const CsvRow = memo(({ csv }: Props) => {
	const [getCsvFile] = useLazyGetCsvFileQuery();
	const [loading, setLoading] = useState(false);

	const downloadCsv = (response: any) => {
		if (response.error) {
			const message = JSON.parse(response.error.data).detail;
			openNotification({ text: message, closable: true });
			setLoading(false);
			return;
		}
		// @ts-ignore
		const blobData = response.data;
		const csvBlob = new Blob([blobData], { type: 'text/csv' });

		// @ts-ignore
		const url = window.URL.createObjectURL(csvBlob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = appendTimestampToFilename('leads', csv.created_at, 'csv');
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);

		setLoading(false);
	};

	const handleClick = () => {
		if (csv.status === 'done') {
			setLoading(true);
			getCsvFile(csv.id).then(downloadCsv);
		}
	};

    const search = useSearch();

    const handleSearch = () => {
        const values = {...csv.filters};
        if ('headcount' in values) {
            values['headcount'] = normalizeHeadcount(values['headcount']);
        }
        search(values, null, true);
    }

	return (
		<>
			<Tr className={styles.profile}>
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Td className={styles.cell} width="144px">
						<Text className={styles.title} variant="inter/14/regular" component="p" color="grey">
							{formatDate(csv.created_at, MONTH_DAY_FORMAT)}, {formatTime(csv.created_at)}
						</Text>
					</Td>
					<Td className={styles.cell} width="180px">
						<div className={styles.search} onClick={handleSearch}>
							<Text className={styles.title} variant="inter/14/regular" component="p">
								<span style={{textDecoration: "underline"}}>Search</span>
							</Text>
							<Icon icon="external" size={20} />
						</div>
					</Td>
					<Td className={styles.cell} width="164px">
						<Text className={styles.title} variant="inter/14/regular" component="p">
							{formatNumberWithCommas(csv.leads_number)} {csv.leads_number === 1 ? 'lead' : 'leads'}
						</Text>
					</Td>
					<Td className={styles.cell} width="144px">
						<Text className={styles.title} variant="inter/14/regular">
							{formatStatus(csv.status)}
						</Text>
					</Td>
					<Td className={styles.cell}>
						<div
							className={cn(
								styles.download,
								{ [styles.downloadCompleted]: csv.status === 'done' },
								{ [styles.downloadFailed]: csv.status === 'failed' },
								{ [styles.downloadPending]: csv.status === 'pending' }
							)}
							onClick={handleClick}>
							{loading ? (
								<Text variant="inter/14/regular">Downloading...</Text>
							) : (
								<>
									<Text variant="inter/14/regular">Download CSV</Text>
									<Icon icon="file" size={20} />
								</>
							)}
						</div>
					</Td>
				</div>
			</Tr>
		</>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return oldProps.csv === newProps.csv;
}
