import { FC } from 'react';
import cn from 'classnames';
import { Tooltip, ConfigProvider } from 'antd';

import { ProgAIResults, ProgAICandidate } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';
import { CandidateMatched } from 'features/candidate';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
	metadata?: ProgAIResults['match_metadata'];
	className?: string;
}

export const CandidateInfo: FC<Props> = ({ data, metadata, className }: Props) => {
	const isRockStar = data.seniority_level === 'Rock Star';

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<section className={cn(styles.info, className)}>
				{data.location && (
					<div className={styles.row}>
						<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
							Location
						</Text>
						<Text variant="inter/14/medium" color="dark_blue">
							{data.location}
						</Text>
					</div>
				)}
				{data.currentIndustry && (
					<div className={styles.row}>
						<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
							Industry
						</Text>
						<Text variant="inter/14/medium" color="dark_blue">
							{data.currentIndustry}
						</Text>
					</div>
				)}
				{data.years_of_employment && (
					<div className={styles.row}>
						<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
							Years of employment in software
						</Text>
						<div className={styles.row}>
							<Text
								variant="inter/14/medium"
								color="dark_blue">{`${data.years_of_employment} years`}</Text>
							<Tooltip
								arrow={false}
								title={
									<div className={styles.tooltip}>
										<Text color="dark_green">Based on their work experience on Linkedin</Text>
									</div>
								}
								placement="rightTop"
								overlayClassName={styles.tooltipOverlay}>
								<Icon icon="info" className={styles.tooltipIcon} />
							</Tooltip>
						</div>
					</div>
				)}
				{data.years_of_experience && (
					<div className={styles.row}>
						<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
							Years of coding experience
						</Text>
						<div className={styles.row}>
							<Text
								variant="inter/14/medium"
								color="dark_blue">{`${data.years_of_experience} years`}</Text>
							<Tooltip
								arrow={false}
								title={
									<div className={styles.tooltip}>
										<Text color="dark_green">Based on their contributions on Github</Text>
									</div>
								}
								placement="rightTop"
								overlayClassName={styles.tooltipOverlay}>
								<Icon icon="info" className={styles.tooltipIcon} />
							</Tooltip>
						</div>
					</div>
				)}
				{data.seniority_level && (
					<div className={cn(styles.row, styles.rowCentered)}>
						<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
							Seniority
						</Text>
						<Text
							variant="inter/14/medium"
							color="dark_blue"
							className={cn(styles.seniority, {
								[styles.seniorityStar]: isRockStar
							})}>
							{isRockStar && <span>🤩</span>}
							{data.seniority_level}
						</Text>
						{isRockStar && (
							<Tooltip
								arrow={false}
								title={
									<div className={styles.tooltip}>
										<Text color="dark_green">
											Rock stars are famous experts in their areas, such as Dan Abramov or Vitalik
											Buterin.
										</Text>
									</div>
								}
								placement="rightTop"
								overlayClassName={styles.tooltipOverlay}>
								<Icon icon="info" className={styles.tooltipIcon} />
							</Tooltip>
						)}
					</div>
				)}
				{/* {metadata && (
					<>
						<div className={styles.rowMatched}>
							<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
								Matched skills
							</Text>
							<Text variant="inter/14/medium" className={styles.match}>
								{`${metadata.match_score} match`}
							</Text>
						</div>
						<div className={styles.row}>
							<Text variant="inter/14/medium" color="grey" className={styles.rowName}></Text>
							<CandidateMatched metadata={metadata} />
						</div>
					</>
				)} */}
			</section>
		</ConfigProvider>
	);
};
